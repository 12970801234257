//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================

.apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-label {
  font-size: 2rem !important;
}

.apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-value {
  font-size: .75rem !important;
}
