@include media-breakpoint-down(md) {
  .back-button {
    padding: 0.5rem;
    margin-right: 0.5rem;

    span {
      display: none;
    }
  }

  h2 {
    font-size: 1.25rem;
  }

  .logout-text {
    display: none;
  }
}

.bookmark-icons, .modern-nav-toggle {
  display: none;
}

.navbar-nav {
  padding: 0.25rem;
  border-radius: 0.358rem;
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 16rem;
}

