.header-navbar {
  &.floating-nav {
    @include media-breakpoint-down(xs) {
      margin: 0 1.2rem;
    }
  }

  .navbar-nav {
    .nav-item {
      @include media-breakpoint-down(md) {
        flex: 0 1 auto !important;
        margin-bottom: 0;
      }
    }
  }
}
