.react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd, .react-calendar-timeline .rct-vertical-lines .rct-vl:not(.rct-vl-first), .react-calendar-timeline .rct-calendar-header, .react-calendar-timeline .rct-header-root {
  border: none !important;
  background: none !important;
}

.react-calendar-timeline .rct-dateHeader {
  border: none !important;
  background: #fff !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
  border-left-width: 1px !important;
}

.custom-item {
  min-width: 110px;
  font-weight: bold !important;
  box-shadow: 0 2px 10px 0 #22292f3b !important;
  background: #fff !important;
  border: none !important;
  color: #0000009e !important;
  border-radius: 0.75rem !important;
  padding-left: 0.5rem !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding-right: 1rem !important;

  .rct-item-content {
    min-width: 110px;
  }
  
  &.add {
    min-width: auto;

    .rct-item-content {
      min-width: auto;
    }

    background: #f4f4f4 !important;
    border: none !important;
    border-radius: 0.75rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin-left: 5px !important;
    font-size: 2rem !important;
    text-align: center !important;
    box-shadow: none;
  }
}
