$toastSuccess: lighten($success, 10);
$toastDanger: lighten($danger, 10);

.Toastify__toast {
  .toastify-header {
    padding: 0;
    width: 100%;

    .toast-title {
      color: #333 !important;
    }
  }

  .Toastify__toast-body {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    .toastify-body {
      padding-left: .75rem;
    }
  }

  &.Toastify__toast--success {
    .Toastify__toast-body {
      border-left: 5px solid $toastSuccess;
    }

    .Toastify__progress-bar {
      background-color: $toastSuccess;
    }
  }

  &.Toastify__toast--error {
    .Toastify__toast-body {
      border-left: 5px solid $toastDanger;
    }

    .Toastify__progress-bar {
      background-color: $toastDanger;
    }
  }
}