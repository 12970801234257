.radio-button input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-button label {
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  padding: 1rem;
  font-family: Montserrat, sans-serif, Arial;
  font-size: 1.1rem;
  border: 1px solid #ddd;
  border-radius: 1rem;
  width: 100%;
  text-align: center;
  line-height: 2rem;
}

.radio-button input[type="radio"]:checked + label {
  border: 1px solid $primary;
}
