.react-dataTable {

  &.row-is-clickable {
    .rdt_TableRow {
      div {
        *:not(button) {
          pointer-events: none;
        }
      }
    }
  }

  &.row-auto-height {
    .rdt_TableBody {
      .rdt_TableRow {
        min-height: initial;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  .rdt_TableCel,
  .rdt_TableRow {
    font-size: 1rem;
    color: $primary;

  }

  .rdt_TableBody {
    .rdt_TableRow {
      min-height: 90px;

      .rdt_TableCell {
        height: auto !important;

        
      }
    }
  }
}