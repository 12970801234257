/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import '../../@core/scss/base/bootstrap-extended/include';
@import '../../@core/scss/base/components/include';
@import 'components/react-calendar-timeline';
@import 'components/react-grid-layout';
@import 'components/radio-button';
@import 'components/input-group';
@import 'components/react-select';
@import 'components/flatpickr';
@import 'components/toast';
@import 'components/badge';
@import 'components/apex';
@import 'components/buttons';
@import 'components/data-table';
@import 'components/tipster';
@import 'components/uppy';
@import 'components/card';
@import 'layout/nav';
@import 'layout/navbar';
@import 'layout/sidebar';
@import 'vendor/rfs';

.header-navbar {
  background: #fff;
  box-shadow: 0 4px 24px 0 #22292f3b;

  a {
    color: $body-color;
  }

  .navbar-container {
    padding: 0;
    align-items: center;

    .navbar-nav {
      padding: 0.5rem;
      border: 1px solid #f6f6f6;
      border-radius: 0.75rem;
      margin-left: initial !important;
      display: flex;
      flex-wrap: nowrap;
    }
  }
}

.card {
  border-radius: 0.75rem;
  box-shadow: 0 4px 24px 0 #22292f3b;

  &.active {
    background-color: #10163a;

    * {
      color: #fff;
    }
  }
}

.nav-tabs .nav-link::after {
  background: none !important;
}

.nav-tabs {
  &:first-child .nav-link {
    border-top-left-radius: 0.75rem;
  }

  .nav-link {
    border-top-right-radius: 0.75rem;
    box-shadow: 0 -7px 10px #ddd;
    border-top-left-radius: 0.75rem;
    background: #fff;

    &.active {
      border-top-left-radius: 0.75rem;
      z-index: 20 !important;
      background: #fff;
    }
  }
}

.vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .dropdown-menu {
  top: 20px !important;
}

.navbar-floating .navbar-container:not(.main-menu-content) {
  padding: 0;
  justify-content: space-between;
}

.vertical-layout {
  .scroll-to-top {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .content-wrapper {
    img.card-img-top {
      object-fit: cover;
      min-width: 100%;
      height: 60%;

      @include media-breakpoint-up(md) {
        height: auto;
      }
    }
  }
}

.gap-4 {
  gap: 1rem
}

.gap-3 {
  gap: .75rem
}

.gap-2 {
  gap: .5rem
}

.gap-1 {
  gap: .25rem
}

.bs-stepper-header {
  display: none !important;
}

.bs-stepper .bs-stepper-content {
  padding: 0 !important;
}

.list-item {
  &:hover {
    background-color: $primary;
    color: #fff;

    svg {
      fill: #fff !important;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $primary
}

.validation-error-message {
  color: darken($danger, 10);
  font-size: 0.857rem;
  margin: 0;
  font-weight: bold;
}

footer.has-padding-bottom {
  padding-bottom: $footerCustomPaddingBottomPhone;

  @include media-breakpoint-up(md) {
    padding-bottom: $footerCustomPaddingBottomTablet;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: $footerCustomPaddingBottomDesktop;
  }
}

.avatar {
  border-radius: 50%;
  background-color: lighten($secondary, 30%);
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
  box-shadow: inset 0 0 3px 1px lighten($secondary, 10%);
}