//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

$primary: #011640;
$secondary: #54595F;
$success: #02762F;
$warning: #F07D00;
$danger: #AA2201;
$white: #ffffff;

$border-color: #ddd;

$footerCustomPaddingBottomPhone: 120px;
$footerCustomPaddingBottomTablet: 200px;
$footerCustomPaddingBottomDesktop: 150px;

[dir] .semi-dark-layout .main-menu, [dir] .semi-dark-layout .main-menu-content .navigation-main {
  background-color: $primary;
}

[dir] .main-menu.menu-dark .navigation > li.active > a {
  background: lighten($primary, 10%);
}

[dir="ltr"] .navbar-floating .header-navbar-shadow {
  background: linear-gradient(180deg, rgba(248, 248, 248, 0.95) 0%, rgba(248, 248, 248, 0.46) 40%, rgba(255, 255, 255, 0));
  left: 0;
}

[dir] .badge {
  color: #6e6b7b;
  font-weight: normal;
  padding: 0.5rem 1.5rem;
}
