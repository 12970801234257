$badgeSuccess: #e3efe1;
$badgeWarning: #e8cc9d;
$badgeDanger: #fabebe;

.badge {
  &-success {
    background: $badgeSuccess
  }
  &-warning {
    background: $badgeWarning
  }
  &-danger {
    background: $badgeDanger
  }
}