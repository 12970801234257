@import '../../../../@core/scss/base/bootstrap-extended/include';

.apexcharts-tooltip {
  opacity: 1 !important;
}

.water-widget {
  .water-widget--item {
    @include media-breakpoint-up(md) {
      &.col {
        &:not(:last-child)::after {
          content: "";
          height: 100%;
          width: 1px;
          background-color: #ddd;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    &.col-6 {
      @include media-breakpoint-up(md) {

        &:not(:last-child)::after {
          content: "";
          height: 100%;
          width: 1px;
          background-color: #ddd;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      &:nth-child(odd)::after {
        display: block;
        content: "";
        height: 100%;
        width: 1px;
        background-color: #ddd;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    @include media-breakpoint-between(md, lg) {
      h5 {
        text-align: center;

        span {
          display: block;
        }
      }
      p {
        font-size: 90% !important;

      }
    }
  }
}
