@media screen and (min-width: 992px) and (max-width: 1120px) {
    .app-tipster-list .tipster-invitation-widget .tipster-widget-info-btn i {
        margin-right:0;
        float:none !important;
    }    
    
    .app-tipster-list .tipster-invitation-widget .tipster-widget-info-btn .label {
        display: none;
    }    
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .app-tipster-list .tipster-invitation-widget .tipster-widget-info-btn i {
        margin-right:0;
        float:none !important;
    }    
    
    .app-tipster-list .tipster-invitation-widget .tipster-widget-info-btn .label {
        display: none;
    }    
}

@media screen and (max-width: 376px) {
    .app-tipster-list .tipster-summary-widget .card-body h5 > .font-large-1 {
        font-size: 1.2rem !important;
    }
    .app-tipster-list .tipster-summary-widget .card-body h5 {
        font-size: 0.8rem !important;
    }
}

@media screen and (min-width: 376px) and (max-width: 991px) {
    .app-tipster-list .tipster-summary-widget .card-body h5 > .font-large-1 {
        font-size: 1.5rem !important;
    }
    .app-tipster-list .tipster-summary-widget .card-body h5 {
        font-size: 1rem !important;
    }
}