.input-group {
  > :not(:first-child) {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.input-group-text {
  &.read-only {
    background-color: $input-disabled-bg;
    opacity: 0.5;
  }
}
