.btn {
  @include transition(background-color .2s ease-in);

  &:hover {
    box-shadow: none !important;
  }

  &.invalid {
    opacity: 0.5;
  }

  &-icon {
    &:not(.no-hidden-span) {
      @include media-breakpoint-down(sm) {
        padding-left: .5rem;
        padding-right: .5rem;
      }

      svg {
        @include media-breakpoint-down(sm) {
          width: 25px !important;
          height: auto !important;
        }
      }
    }

    &.no-mobile-bg {
      @include media-breakpoint-down(sm) {
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }

    &.view-switch {
      @include media-breakpoint-down(sm) {
        padding: 0.25rem 1rem 0.25rem 0.5rem !important;

        svg {
          width: 20px !important;
          height: auto !important;
        }
      }
    }
  }

  &:not(.no-hidden-span) svg + span {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
