body[asset-hide-sidebar="true"] {
  .header-navbar.floating-nav {
    width: calc(100vw - (100vw - 100%) - calc(2rem * 2));
    left: 0 !important;
  }

  .main-menu {
    left: -260px;
  }

  .content {
    margin-left: 0;
  }

  .navbar-nav.d-xl-none {
    display: block !important;
  }
}