.drag-handle {
  cursor: pointer;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  top: 0;
}

.react-grid-layout {
  @include media-breakpoint-down(sm) {
    height: auto !important;
  }
}

.react-grid-item {
  @include media-breakpoint-down(sm) {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    transform: translate(0) !important;
    height: auto !important;
    margin-bottom: 0.5rem !important;
  }
}